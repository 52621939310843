/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NavBar({ hasBack }) {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);  // 状态用于控制下拉菜单的显示

  const handleBack = () => {
    navigate(-1);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setShowMenu(false); 
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="fixed top-0 left-0 right-0 bg-white shadow-md flex justify-between items-center p-4 z-50">
      {hasBack ? (
        <button onClick={handleBack} className="text-lg text-blue-500">
          &#x2190;
        </button>
      ) : (
        <div className="flex-grow"></div>
      )}
      <div className="relative">
        <button onClick={toggleMenu} className="text-xl text-blue-500">
          <i className="fas fa-globe"></i>
        </button>
        {showMenu && (
          <div className="absolute right-0 mt-2 py-2 w-20 bg-white rounded-md shadow-xl z-20">
            <a href="#" onClick={() => changeLanguage('zh')} className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white">
              中文
            </a>
            <a href="#" onClick={() => changeLanguage('en')} className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white">
              English
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default NavBar;
