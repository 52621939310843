import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import './modal.css'

function Modal({ isLoading, vipNumber, recipient, onClose }) {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [isCopied, setIsCopied] = useState(false);

    const handleCreate = () => {
        onClose();
        navigate('/new');
    };

    const handleConfirm = () => {
        if (navigator.clipboard && vipNumber) {
            const text = `浙江省东阳市江北街道湖莲西街335号\n永鸿电商工业园2号楼2楼 ${vipNumber}号\n${t('vip_modal_recipient')}${recipient} 朋涞\n${t('tel_phone')}13325895559`
            navigator.clipboard.writeText(text).then(() => {
                setIsCopied(true)
                console.log('VIP Number Copied');
            }).catch(err => {
                console.error('Cannot copy VIP Number:', err);
            });
        }
        // onClose();
        // navigate('/');
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="w-80 bg-white p-4 rounded-lg shadow-lg flex flex-col items-center relative">
                {/* { !vipNumber && ( */}
                    <button onClick={ () => { onClose() }} className="absolute top-2 right-3 text-lg text-gray-600 hover:text-gray-900">
                        &#x2715;
                    </button>
                {/* )} */}
                {isLoading ? (
                <div className="m-auto">
                    <div className="loader"></div>
                </div>
                ) : vipNumber ? (
                <div className="text-center m-auto">
                    <h2 className="text-lg mb-1">{t('vip_modal_title')}</h2>
                    <p className="text-xl font-bold mb-2">{vipNumber}</p>
                    <p className="text-xs mb-1 text-gray-500 mb-2">{t('vip_modal_shipping_address')}</p>
                    <p className="text-xs mb-2">{`浙江省东阳市江北街道湖莲西街335号`}</p>
                    <p className="text-xs mb-2">{`永鸿电商工业园2号楼2楼 ${vipNumber}号`}</p>
                    <p className="text-xs mb-2">{`${t('vip_modal_recipient')}${recipient} 朋涞`}</p>
                    <p className="text-xs">{`${t('vip_modal_tel_phone')}13325895559`}</p>
                    <div className='mt-4 flex-row'>
                        <button onClick={handleConfirm} className={`self-center ${isCopied ? 'bg-green-500' : 'bg-blue-500'} hover:${isCopied ? 'bg-green-700' : 'bg-blue-700'} text-white font-bold py-2 px-4 rounded`}>
                            {isCopied ? t('vip_modal_copy_button_copied') : t('vip_modal_copy_button')}
                        </button>
                    </div>
                </div>
                ) : (
                <div className="text-center m-auto">
                    <h2 className="text-lg mb-4">{t('vip_modal_no_search_result')}</h2>
                    <button onClick={handleCreate} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {t('new_vip_title')}
                    </button>
                </div>
                )}
            </div>
        </div>
    );
}

export default Modal;
