import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import {
  APICreateVIP
} from '../../apis/api';
import Modal from '../modal/modal';
import NavBar from '../navbar/navbar';

export function Loc2Code(location, t) {
  let code = "L"
  if (location === t('loc_lasalle')) {
    code = "L"
  } else if (location === t('loc_ile_des_soeurs')) {
    code = "S"
  } else if (location === t('loc_rosement')) {
    code = "R"
  } else if (location === t('loc_oakville')) {
    code = "O"
  } else if (location === t('loc_vaughan')) {
    code = "V"
  } else if (location === t('loc_kanata')) {
    code = "K"
  } else if (location === t('loc_gatineau')) {
    code = "G"
  }
  return code
}

function NewVIP() {
  const { t, i18n } = useTranslation();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [location, setLocation] = useState('L');

  const [isLoading, setIsLoading] = useState(true);
  const [vipNumber, setVipNumber] = useState('');
  const [recipient, setRecipient] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleLanguageChange = () => {
      const select = document.getElementById("location");
      if (select) {
        select.selectedIndex = 0;
      }
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let code = Loc2Code(location, t)
    setIsLoading(true)
    setShowModal(true)
    APICreateVIP(name, email, phone, code, (success, json) => {
      setVipNumber(json.vip || null)
      setRecipient(json.name || null)
      setIsLoading(false)
    })
  };

  return (
    <div>
      <NavBar hasBack={true} />
      <div className="mt-16 flex justify-center">
        <div className="w-full max-w-lg p-5 bg-white rounded-lg">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-xl font-semibold">{t('new_vip_title')}</h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                {t('new_vip_name')}<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="name"
                required
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                {t('new_vip_email')}<span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                id="email"
                required
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                {t('new_vip_phone')}<span className="text-red-500">*</span>
              </label>
              <input
                type="tel"
                id="phone"
                required
                defaultValue={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="location">
                {t('new_vip_pickup_location')}
              </label>
              <select
                id="location"
                onChange={(e) => setLocation(e.target.value)}
                className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline"
              >
                <option>{t('loc_lasalle')}</option>
                <option>{t('loc_ile_des_soeurs')}</option>
                <option>{t('loc_rosement')}</option>
                <option>{t('loc_oakville')}</option>
                <option>{t('loc_vaughan')}</option>
                <option>{t('loc_kanata')}</option>
                <option>{t('loc_gatineau')}</option>
              </select>
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {t('new_vip_submit')}
            </button>
          </form>
        </div>
        {showModal && <Modal isLoading={isLoading} vipNumber={vipNumber} recipient={recipient} onClose={() => setShowModal(false)} />}
      </div>
    </div>
  );
}

export default NewVIP;
